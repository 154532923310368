import React from 'react';
import PropTypes from 'prop-types';

import s from './PressHeader.scss';

const PressHeader = ({ heading, aside, children }) => (
  <div className={s.pressHeader}>
    <div className={s.pressHeader__inner}>
      <div className={s.pressHeader__container}>
        <h1 className={s.pressHeader__heading}>{heading}</h1>
        { aside && (<aside className={s.pressHeader__aside}>{aside}</aside>)}
        {children}
      </div>
    </div>
  </div>
);

PressHeader.propTypes = {
  heading: PropTypes.string,
  aside: PropTypes.element,
  children: PropTypes.node,
};

PressHeader.defaultProps = {
  children: undefined,
};

export default PressHeader;
