import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GreyArea from 'components/grey-area';
import Segment, { Container, Row } from 'components/layout';
import Leader from 'components/leader';
import Callout from 'components/callout';

import { Logo, LogoUsage } from '.';

import s from './LogoGuide.scss';

export default class LogoGuide extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    logos: PropTypes.array,
    usageHeading: PropTypes.string,
    usageText: PropTypes.string,
    usageDoImages: PropTypes.array,
    usageDontImages: PropTypes.array,
    usageDonts: PropTypes.array,
    downloadHeading: PropTypes.string,
    downloadFile: PropTypes.string,
  }

  static defaultProps = {
    heading: 'Logo guidelines',
    text: null,
    logos: [],
    downloadHeading: 'Download Proxy Press Kit',
    downloadFile: null,
  }

  render() {
    const {
      heading,
      text,
      logos,
      usageHeading,
      usageText,
      usageDoImages,
      usageDontImages,
      usageDonts,
      downloadHeading,
      downloadFile,
    } = this.props;

    return (
      <GreyArea>
        <Segment>
          <Leader
            heading={heading}
            text={text}
            className={s.logoGuide__leader}
          />
          <div className={s.logoGuide__grid}>
            <Container>
              <Row>
                {logos.map((logo, i) => {
                  const large = i === 0;

                  return (
                    <div
                      className={
                        s('logoGuide__logo', { logoGuide__logoLarge: large, logoGuide__logoSmall: !large })
                      }
                      key={logo.file.url}
                    >
                      <Logo
                        image={{
                            url: logo.file.url,
                            width: logo.file.details.image.width,
                            height: logo.file.details.image.height,
                          }}
                        text={logo.description}
                        link={`https:${logo.file.url}`}
                        large={large}
                      />
                    </div>);
                  })}
              </Row>
            </Container>
          </div>

          <LogoUsage
            heading={usageHeading}
            text={usageText}
            dontList={usageDonts}
            dontImages={usageDontImages}
            doImages={usageDoImages}
          />

          <Container>
            <Callout
              heading={downloadHeading}
              theme="white"
              cta={{
                text: 'Download',
                url: downloadFile,
                icon: 'download',
              }}
            />
          </Container>
        </Segment>
      </GreyArea>
    );
  }
}
