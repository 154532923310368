import React from 'react';
import PropTypes from 'prop-types';

import s from './TabButton.scss';

const TabButton = ({ onClick, text, active, index, controlID, theme }) => (
  <button
    aria-selected={active}
    aria-controls={controlID}
    role="tab"
    className={s('tabButton', theme, { active, [s.first]: index === 0 })}
    onClick={onClick}
    title={text}
  >
    <span className={s.tabButton__inner}>{text}</span>
  </button>
);

TabButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  controlID: PropTypes.string,
  index: PropTypes.number,
  theme: PropTypes.string,
};

export default TabButton;
