import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GreyArea from 'components/grey-area';
import Leader from 'components/leader';
import Segment, { Container, Row } from 'components/layout';
import ArticleCard from 'components/article-card';

import s from './ArticleList.scss';

export default class ArticleList extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        source: PropTypes.string,
        image: PropTypes.string,
        link: PropTypes.string.isRequired,
      }),
    ),
  }

  static defaultProps = {
    heading: 'Highlights',
    articles: [],
  }

  render() {
    const { heading, articles } = this.props;

    return (
      <GreyArea>
        <Segment>
          {heading && <Leader heading={heading} centered />}
          <Container>
            <Row>
              {articles.map(article => (
                <div key={article.link} className={s.articleList__item}>
                  <ArticleCard
                    heading={article.heading}
                    source={article.source}
                    image={article.image}
                    link={article.link}
                  />
                </div>
              ))}
            </Row>
          </Container>
        </Segment>
      </GreyArea>
    );
  }
}
