import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';

import s from './PressRelease.scss';

export default class PressRelease extends PureComponent {

  static propTypes = {
    heading: PropTypes.string.isRequired,
    source: PropTypes.string,
    link: PropTypes.string.isRequired,
    date: PropTypes.string,
  }

  static defaultProps = {
    source: null,
    date: null,
  }

  render() {
    const { heading, source, link, date } = this.props;

    return (
      <div key={link} className={s.pressRelease}>
        <p className={s.pressRelease__source}>
          {date &&
            <span className={s.pressRelease__date}>
              {date}
            </span>
          }
          {source &&
            <span>{source}</span>
          }
        </p>
        <h5 className={s.pressRelease__heading}>
          <Clickable to={link} className={s.pressRelease__link}>
            {heading}
          </Clickable>
        </h5>
      </div>
    );
  }
}
