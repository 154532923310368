/* eslint-disable react/no-array-index-key */
import React, { Children, Component, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';

import { TabButton } from '.';

import s from './Tabs.scss';

export default class Tabs extends Component {

  state = {
    activeTab: this.props.activeTab,
  }

  onUpdate = (i) => {
    this.setState({
      activeTab: i,
    });
  }

  static propTypes = {
    labels: PropTypes.array,
    children: PropTypes.node,
    activeTab: PropTypes.number,
    theme: PropTypes.oneOf(['block', 'linear']),
  }

  static defaultProps = {
    activeTab: 0,
    theme: 'linear',
  }

  render() {
    const { labels, theme, children } = this.props;

    const { activeTab } = this.state;

    return (
      <div className={s('tabs', theme)}>
        <Container>
          <ul className={s.tabs__list}>
            {labels.map((label, i) => (
              <li className={s.tabs__listItem} key={`${s.tabs}-${i}`}>
                <TabButton
                  controlID={`${s.tabs__item}-${i}`}
                  index={i}
                  active={i === activeTab}
                  onClick={() => { this.onUpdate(i); }}
                  text={label}
                  theme={theme}
                />
              </li>
            ))}
          </ul>
        </Container>

        {Children.map(children, (child, i) => {
          if (!child) {
            return null;
          }

          const id = `${s.tabs__item}-${i}`;

          return (
            <div className={s.tabs__item}>
              {cloneElement(child,
              { active: i === activeTab, index: i, id, key: id, theme })}
            </div>
          );
        })}
      </div>
    );
  }
}
