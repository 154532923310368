import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Image from 'components/image';
import { Container, Row } from 'components/layout';

import s from './LeadArticle.scss';

const LeadArticle = ({ heading, source, image, link }) => (
  <div className={s.leadArticle}>
    <div className={s.leadArticle__inner}>
      <Container>
        <Row>
          <div className={s.leadArticle__content}>
            <div className={s.leadArticle__hgroup}>
              <h2 className={s.leadArticle__heading}>{heading}</h2>
              <h3 className={s.leadArticle__kicker}>{source}</h3>
            </div>
            <div className={s.leadArticle__buttonWrap}>
              <Button color="transparent" to={link}>Read more</Button>
            </div>
          </div>

          <div className={s.leadArticle__imageWrap}>
            <div className={s.leadArticle__imageInner}>
              {image && (
                <div className={s.leadArticle__image}>
                  <Image
                    src={`${image.file.url}?w=${image.file.details.image.width}`}
                    src2x={`${image.file.url}?w=${image.file.details.image.width * 2}`}
                    width={image.file.details.image.width}
                    height={image.file.details.image.height}
                  />
                </div>
              )}
            </div>
          </div>

        </Row>
      </Container>
    </div>
  </div>
);

LeadArticle.propTypes = {
  source: PropTypes.string,
  heading: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object,
};

export default LeadArticle;
