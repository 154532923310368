import React from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';

import ArrowDown from 'assets/svg/icons/arrow-down.svg';

import s from './DownloadButton.scss';

const DownloadButton = ({ to, children }) => (
  <Clickable className={s.downloadButton} to={to}>
    <span className={s.downloadButton__icon}><ArrowDown className={s.downloadButton__svg} /></span>
    <span className={s.downloadButton__inner}>{children}</span>
  </Clickable>
);

DownloadButton.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

DownloadButton.defaultProps = {
  children: 'download',
};

export default DownloadButton;
