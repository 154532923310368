import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Segment from 'components/layout';
import Leader from 'components/leader';
import ImageSet from 'components/image-set';
import Tabs, { TabContent } from 'components/tabs';

import s from './PhotoGuide.scss';

export default class PhotoGuide extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    imageSets: PropTypes.array,
  }

  static defaultProps = {
    heading: 'Photography',
    imageSets: [],
  }

  render() {
    const { heading, imageSets } = this.props;
    const tabs = imageSets.map(imageSet => imageSet.heading);

    return (
      <Segment>
        <Leader heading={heading} />
        <Tabs
          labels={tabs}
          activeTab={0}
        >
          {imageSets.map(imageSet => (
            <TabContent key={imageSet.heading}>
              <div className={s.photoGuide}>
                <ImageSet
                  images={imageSet.images.map(image => ({
                    src: _get(image, 'file.url'),
                    caption: {
                      // heading: image.title,
                      text: image.description,
                    },
                    orientation: imageSet.imageOrientation,
                  }))}
                />
              </div>
            </TabContent>
          ))}
        </Tabs>
      </Segment>
    );
  }
}
