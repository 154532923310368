import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import DownloadButton from 'components/download-button';

import s from './Logo.scss';

export default class Logo extends PureComponent {

  static propTypes = {
    image: PropTypes.object,
    text: PropTypes.string,
    link: PropTypes.string,
    large: PropTypes.bool,
  }

  static defaultProps = {
    text: 'Logo',
    link: '#',
  }

  render() {
    const { image, text, link, large } = this.props;

    return (
      <div className={s('logo', { large })}>
        {image && (
          <div className={s.logo__image}>
            <Image
              src={image.url}
              width={image.width}
              height={image.height}
            />
          </div>
        )}
        <div className={s.logo__footer}>
          <p className={s.logo__caption}>{text}</p>

          <DownloadButton to={link} />
        </div>
      </div>
    );
  }
}
