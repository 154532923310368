import { graphql } from 'gatsby';
import News from 'routes/news/News';

export default News;

export const query = graphql`
  fragment article on ContentfulArticle {
    image {
      ...image
    }
    heading: title
    source
    link
    date(formatString: "MMMM DD, YYYY")
  }

  query News {
    page: contentfulPageNews {
      pageTitle
      metaDescription {
        text: metaDescription
      }
      metaImage {
        ...image
      }
      contactHeading: contactTitle
      contactText: contactBody {
        text: contactBody
      }
      showArticles
      featuredArticle {
        ...article
      }
      articlesHeading: articlesTitle
      articles {
        ...article
      }
      pressKitIntro {
        ...moduleList
      }
      logoHeading: logoTitle
      logoText: logoBody {
        text: logoBody
      }
      logos {
        ...image
      }
      usageHeading: usageTitle
      usageText: usageBody {
        text: usageBody
      }
      usageDoImages {
        ...image
      }
      usageDontImages {
        ...image
      }
      usageDonts
      downloadHeading: downloadTitle
      downloadFile {
        ...image
      }
      photoHeading: photographyTitle
      imageSets: photographySets {
        ...imageSet
      }
      showPressReleases
      pressReleaseList {
        ...article
      }
      cta {
        ...moduleList
      }
    }
  }
`;
