import React from 'react';
import PropTypes from 'prop-types';

import s from './TabContent.scss';

const TabContent = ({ children, active, theme }) => (
  <div className={s('tabContent', theme, { active })} role="tabpanel">
    {children}
  </div>
);

TabContent.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  theme: PropTypes.string,
};

TabContent.defaultProps = {
  children: undefined,
};

export default TabContent;
