import React from 'react';
import PropTypes from 'prop-types';

import Markdown from 'components/markdown';

import s from './Contact.scss';

const Contact = ({ heading, name, tel, email, text }) => (
  <address className={s.contact}>
    { heading && (<div className={s.contact__heading}>{heading}</div>)}
    {name && (<div className={s.contact__name}>{name}</div>)}
    {email && (<a href={`mailto:${email}`} className={s.contact__link}>{email}</a>)}
    {tel && (<a href={`tel:${tel}`} className={s.contact__link}>{tel}</a>)}
    {text && <Markdown className={s.contact__markdown} source={text} />}
  </address>
);

Contact.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  tel: PropTypes.string,
  email: PropTypes.string,
  text: PropTypes.string,
};

export default Contact;
