import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import Modules from 'containers/modules';
import Tabs, { TabContent } from 'components/tabs';
import Segment, { Container } from 'components/layout';

import extractMeta from 'utils/extract-meta';

import Contact from './components/contact';
import PressHeader from './components/press-header';
import LogoGuide from './components/logo-guide';
import PhotoGuide from './components/photo-guide';
import LeadArticle from './components/lead-article';
import ArticleList from './components/article-list';
import PressRelease from './components/press-release';

import SEO from 'components/seo';

export default class News extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;
    const tabs = ['Press Kit'];

    if (page.showArticles) {
      tabs.push('Articles');
    }

    if (page.showPressReleases) {
      tabs.push('Press Releases');
    }

    return (
      <Fragment>
        <SEO {...extractMeta(page)} pathname={pathname} article />
        <PressHeader
          heading={page.pageTitle}
          aside={(
            <Contact
              heading={page.contactHeading}
              text={page.contactText.text}
            />
          )}
        />

        <Tabs labels={tabs}>
          <TabContent>
            <Modules list={[page.pressKitIntro]} />
            <LazyLoad once offset={500}>
              <LogoGuide
                heading={page.logoHeading}
                text={page.logoText.text}
                logos={page.logos}
                usageHeading={page.usageHeading}
                usageText={page.usageText.text}
                usageDoImages={page.usageDoImages}
                usageDontImages={page.usageDontImages}
                usageDonts={page.usageDonts}
                downloadHeading={page.downloadHeading}
                downloadFile={`https:${page.downloadFile.file.url}`}
              />
            </LazyLoad>
            <LazyLoad once offset={500}>
              <PhotoGuide
                heading={page.photoHeading}
                imageSets={page.imageSets}
              />
            </LazyLoad>
          </TabContent>

          {page.showArticles &&
            <TabContent>
              <LeadArticle
                source={page.featuredArticle.source}
                heading={page.featuredArticle.heading}
                link={page.featuredArticle.link}
                image={page.featuredArticle.image}
              />
              <LazyLoad once offset={500}>
                <ArticleList
                  heading={page.articlesHeading}
                  articles={page.articles.map(article => ({
                    heading: article.heading,
                    source: article.source,
                    image: _get(article.image, 'file.url'),
                    link: article.link,
                  }))}
                />
              </LazyLoad>
            </TabContent>
          }

          {page.showPressReleases &&
            <TabContent>
              <Segment>
                <Container>
                  {page.pressReleaseList.map(pressRelease => (
                    <PressRelease
                      key={pressRelease.link}
                      heading={pressRelease.heading}
                      source={pressRelease.source}
                      link={pressRelease.link}
                      date={pressRelease.date}
                    />
                  ))}
                </Container>
              </Segment>
            </TabContent>
          }
        </Tabs>

        <Modules list={[page.cta]} />
      </Fragment>
    );
  }
}
