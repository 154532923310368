import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import NoIcon from 'assets/svg/icons/no-small.svg';
import Cross from 'assets/svg/icons/cross-thin.svg';
import Check from 'assets/svg/icons/check.svg';

import { Container, Row } from 'components/layout';
import Image from 'components/image';

import s from './LogoUsage.scss';

export default class LogoUsage extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    dontList: PropTypes.array,
    dontImages: PropTypes.array,
    doImages: PropTypes.array,
  }

  render() {

    const { heading, text, dontList, dontImages, doImages } = this.props;

    return (
      <div className={s.logoUsage}>
        <Container>
          <h3 className={s.logoUsage__heading}>{heading}</h3>

          <Row>
            <div className={s.logoUsage__content}>
              <p className={s.logoUsage__text}>{text}</p>

              <ul className={s.logoUsage__list}>
                {dontList && dontList.map(item => (
                  <li key={item} className={s.logoUsage__item}>
                    <span className={s('logoUsage__icon', 'list')}><NoIcon /></span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div className={s.logoUsage__images}>
              <Row>
                {doImages.map(image => (
                  <div className={s.logoUsage__imageWrap} key={image.file.url}>
                    <div className={s.logoUsage__placeholder}>
                      <div className={s.logoUsage__image}>
                        <Image
                          src={image.file.url}
                          width={image.file.details.image.width}
                          height={image.file.details.image.height}
                        />
                      </div>
                      <span className={s('logoUsage__icon', 'green')}><Check /></span>
                    </div>
                  </div>
                ))}

                {dontImages.map(dontLogo => (
                  <div className={s.logoUsage__imageWrap} key={dontLogo.file.url}>
                    <div className={s.logoUsage__placeholder}>
                      <div className={s.logoUsage__image}>
                        <Image
                          src={dontLogo.file.url}
                          width={dontLogo.file.details.image.width}
                          height={dontLogo.file.details.image.height}
                        />
                      </div>
                      <span className={s('logoUsage__icon', 'red')}><Cross /></span>
                    </div>
                  </div>
                ))}

              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
